export var ErrorCodeCategory;
(function (ErrorCodeCategory) {
    ErrorCodeCategory["accessDenied"] = "kAccessDenied";
    ErrorCodeCategory["notLoggedIn"] = "kNotLoggedIn";
    ErrorCodeCategory["validationError"] = "kValidationError";
})(ErrorCodeCategory || (ErrorCodeCategory = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode["ACCESS_DENIED_INVALID_TOKEN"] = "mom.accessDenied.invalidToken";
    ErrorCode["CONFLICT_INSTANCE_DELETE"] = "mom.conflict.instanceDelete";
    ErrorCode["ENTITY_ACCESS_EXISTS"] = "mom.conflict.entityAccessExists";
    ErrorCode["IDENTITY_NOT_VERIFIED"] = "iam.accessDenied.unverified";
    ErrorCode["ACCESS_DENIED_REDEPLOYMENT"] = "mom.accessDenied.redployment";
})(ErrorCode || (ErrorCode = {}));
